import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../styles/media'
import {
  BodyText,
  BuyLink,
  Divider,
  Grid,
  HeadingText,
  PriceTag,
  Product,
  ProductImage,
  ProductImgContainer,
  SubHeadingText,
  Title,
} from '../styles'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const ProductsCon = styled.div`
  margin: var(--gutter-s) 0;
  display: block;
  .filter {
    margin: 0 var(--gutter-s);
  }
  .showFilter {
    display: flex;
    p {
      margin-left: 1em;
      margin-top: 5%;
    }
  }
  ${media.laptop`
    margin: var(--gutter-m);
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    button {
      grid-column: 2;
    }
    .filter {
      margin: 0;
    }
  `}
`

const SearchResultsPage = ({ data }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch(
      typeof document !== undefined
        ? document.location.search.substring(7).split('=')[0]
        : ''
    )
  }, [])

  const { edges: products } = data.allShopifyProduct

  return (
    <>
      <Navigation />
      <SEO title="Home" />
      <HeadingText className="black spaced">
        Results For &ldquo;{search}&rdquo;
      </HeadingText>
      <Divider />
      <ProductsCon>
        <div className="filter">
          <SubHeadingText>Result Type</SubHeadingText>
          <BodyText className="black">
            Product (
            {
              products.filter(
                p =>
                  p.node.title.toUpperCase().includes(search.toUpperCase()) ||
                  p.node.productType
                    .toUpperCase()
                    .includes(search.toUpperCase()) ||
                  (p.node.title.toUpperCase().includes(search.toUpperCase()) &&
                    p.node.productType
                      .toUpperCase()
                      .includes(search.toUpperCase()))
              ).length
            }
            )
          </BodyText>
        </div>
        <Grid>
          {products
            .filter(
              p =>
                p.node.title.toUpperCase().includes(search.toUpperCase()) ||
                p.node.productType
                  .toUpperCase()
                  .includes(search.toUpperCase()) ||
                (p.node.title.toUpperCase().includes(search.toUpperCase()) &&
                  p.node.productType
                    .toUpperCase()
                    .includes(search.toUpperCase()))
            )
            .map((p, i) =>
              !p ? (
                <p>Nothings with : {search} </p>
              ) : (
                <div
                  className="column is-3"
                  style={{ marginBottom: '40px' }}
                  key={i}
                >
                  <Product key={i}>
                    <ProductImgContainer>
                      <ProductImage
                        fluid={p.node.images[0].localFile.childImageSharp.fluid}
                        alt={p.node.handle}
                      />
                    </ProductImgContainer>
                    <Title>{p.node.title}</Title>
                    <PriceTag>R{p.node.variants[0].price}</PriceTag>
                    <BuyLink to={`/product/${p.node.handle}/`}>
                      Add to Cart
                    </BuyLink>
                  </Product>
                </div>
              )
            )}
        </Grid>
      </ProductsCon>
      <Footer />
    </>
  )
}

export default SearchResultsPage

export const query = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          title
          handle
          createdAt(fromNow: true)
          publishedAt
          productType
          vendor
          priceRange {
            maxVariantPrice {
              amount
            }
          }
          images {
            originalSrc
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          variants {
            id
            title
            price
          }
        }
      }
    }
  }
`
